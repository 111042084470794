export default class home {

	constructor() {

			
		const videos = document.querySelectorAll('video');
		
		this.initializePage();
		this.playPauseVideos(videos);
		this.checkForSnap();
		this.emailLinkClick();
		setTimeout(() => {
            this.loadVideosSequentially(videos, 0);
        }, 5000);

	}

	initializePage() {
		
		

	}

	playPauseVideos(videos) {
		
	
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.3
		};
	
		const observer = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {

					const article = entry.target.closest('article');
					if (article && !article.classList.contains('inview')) {
						article.classList.add('inview');
					}

					if(entry.target.src === '') {
						entry.target.src = entry.target.dataset.src;
					}

					if (entry.target.readyState >= 3) {
						entry.target.play().catch(e => {
							console.error('Error attempting to play video:', e);
						});
					} else {
						// Attach an event listener if the video isn't ready
						entry.target.addEventListener('canplaythrough', () => {
							entry.target.classList.add('loaded');
							// Check if the video is still intersecting before playing
							if (observer.root || (entry.boundingClientRect.top < window.innerHeight && entry.boundingClientRect.bottom >= 0)) {
								entry.target.play().catch(e => {
									console.error('Error attempting to play video when ready:', e);
								});
							}
						}, { once: true });
					}

					if (article && article.nextElementSibling) {
						const nextVideo = article.nextElementSibling.querySelector('video');
						if (nextVideo) {
							nextVideo.src = nextVideo.dataset.src;
						}
					}

				} else {
					entry.target.pause();
				}
			});
		}, options);
	
		videos.forEach(video => observer.observe(video));
		
	}

	loadVideosSequentially(videos, index) {
		if (index < videos.length) {
			const video = videos[index];
	
			// Function to add loaded class
			const addLoadedClass = () => {
				video.classList.add('loaded');
				this.loadVideosSequentially(videos, index + 1);
			};
	
			video.onloadeddata = addLoadedClass;
	
			// Check if video is already loaded
			if (video.readyState >= 3) {
				addLoadedClass();
			} else if (video.src === '') {
				video.src = video.dataset.src;
				video.load(); // This will trigger onloadeddata
			}
		}
	}
	
	

	checkForSnap() {
		const archive = document.querySelector('.archive');

		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					document.documentElement.classList.add('prevent-snap');
				} else {
					document.documentElement.classList.remove('prevent-snap');
				}
			});
		}, {
			root: null,
			threshold: 0
		});

		observer.observe(archive);
	}

	emailLinkClick() {
		document.querySelectorAll('.email-link').forEach(link => {
			link.addEventListener('click', function(event) {
				
		
			if(!this.classList.contains('clicked')){
		
				event.preventDefault();
		
				this.classList.add('clicked');
		
				const email = this.getAttribute('href').replace('mailto:', '');
				navigator.clipboard.writeText(email).then(() => {
					console.log('Email address copied to clipboard');
				}).catch(err => {
					console.error('Error in copying text: ', err);
				});
		
				this.classList.add('hide-icon');
				const originalText = this.textContent;
				this.textContent = 'email copied';
				
				setTimeout(() => {
					this.classList.remove('hide-icon');
					this.innerHTML = '<span>studio@jakedowsmith.com</span>';
				}, 2000);
			}
			});
		});
	}

}